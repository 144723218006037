<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('menu.servicios')}` }}
        </v-card-title>
        <v-card-text>
          <ServiciosList />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ServiciosList from './form/ServiciosList.vue'

export default {
  components: {
    ServiciosList,
  },
}
</script>
